@import "./assets/base.scss";


.MuiDateRangeCalendar-root .css-e47596-MuiDateRangeCalendar-root + div {
    display: none !important;
    background-color: green !important;
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-71vzt-MuiPaper-root-MuiPickersPopper-paper > div > div > div > div:nth-child(1) {
    display: none !important;
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper > div > div > div > div:nth-child(1) {
    display: none !important;
}