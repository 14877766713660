
$colors: (
  primary: hsl(20, 100%, 63%),
  primary-dark: #070507,
  background: #1b1a21,
  background-light: #292733,
  description: #949499,
  iceberg: #c1e0d8,
  offwhite: #fefdfd,
);

@each $key, $value in $colors {
  .bg-#{$key} {
    background-color: $value;
  }

  .text-#{key} {
    color: $value;
  }
}